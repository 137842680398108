import useApi from '../API/API'
import { TOKEN } from '../Constants'

export default function useAuthService() {

    const API = useApi()

    return {
        login: (username, password) => {
            return API.post('jwt-auth/v1/token', {
                username: username,
                password: password
            })
                .then(res => {
                    if (res.data.role.includes('sm_doctor') || res.data.role.includes('administrator')){
                        if (res.data.token) {
                            localStorage.setItem(TOKEN, JSON.stringify(res.data));
                        }
                    }
                })
        },
        logout: () => {
            localStorage.removeItem(TOKEN);
        },
        getCurrentUser: () => {
            if (localStorage.getItem(TOKEN)){
                return (JSON.parse(localStorage.getItem(TOKEN)))
            }
            return null
        }
    }
}

export function getCurrentUser () {
    if (localStorage.getItem(TOKEN)){
        return (JSON.parse(localStorage.getItem(TOKEN)))
    }
    return null
}