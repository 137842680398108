import { Paper } from "@material-ui/core";

export default function Attachment() {
    return (
        <Paper
            className="edu scrollbox_holder"
            square >
            <div className="scrollbox" style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 align="center"> Attachment </h3>
                <p>Az attachmentek néhány esetben elégethetetlenek a sikeres kezeléshez. Vannak bizonyos mozgatások például a vertikális mozgatás vagy a rotálás mely sok esetben nem tud megvalósulni ezek nélkül. Az attachmentek esztétikus fog színű tömőanyagból készülnek.</p>
                <img src="https://smilezor.hu/wp-content/uploads/2020/09/atachmanat.png" alt="edu" />
            </div>
        </Paper>
    )
}