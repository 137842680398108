import { useEffect, useState } from "react"
import { IMAGE_URL } from "../Constants"
import Slider from "./Slider"
import useApi from "../API/API"

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from "@material-ui/core"

export default function ImagePreview({medical}) {

    const API = useApi()

    const [types, setTypes] = useState(null)

    const [images, setImages] = useState([])
    const [sliderIndex, setSliderIndex] = useState(0)
    const [showSlider, setShowSlider] = useState(false)

    const handleClick = (index, type) => {
        setImages(medical.pictures.filter(e => e.type == type).map(e => e.filename))
        setSliderIndex(index)
        setShowSlider(true)
    }

    const deleteImage = (filename) => {
        const confirmed = window.confirm('Biztosan törölni szeretnéd?')
        if (confirmed) {
            API.delete(`smd/v1/files`, {
                'UID': medical.ID,
                'name': filename
            }).then(res => {
                if (res.success){
                    const newImages = images.filter(i => i.filename != filename)
                    setImages(newImages)
                }
            })
        }
    }

    useEffect(() => {
        let imageSet = new Set()

        try {
            medical.pictures.forEach(picture => {
                imageSet.add(picture.type)
            });
    
            setTypes(imageSet)
        } catch (e){

        }
        
    }, [])

    if (medical) {

        return (
            <>
                {showSlider &&
                    <Slider
                        images={images}
                        index={sliderIndex}
                        setShowSlider={setShowSlider}
                        ID={medical.ID} />
                }
                <div className="scrollbox">
                    <div className="scrollbox_inner">

                        {types &&
                            Array.from(types).map(e => {
                                return (
                                    <ImageAccordion
                                        type={e}
                                        images={medical.pictures}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div className="scrollbox">
                <div className="scrollbox_inner">
                    <h3 align="center"> Nincsenek feltöltött képek </h3>
                </div>
            </div>
        )
    }

    function ImageAccordion({ type, images }) {

        let name = type

        if (type == 'pic_teeth') name = 'Képek'
        if (type == 'pic_xray') name = 'Röntgen képek'

        let filtered = images.filter(image => image.type == type)

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3>{name}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="image-grid">
                        {
                            filtered.map((image, index) => {
                                return (
                                    <div className="holder">
                                        <div className="thumbnail">
                                            <img
                                                width={'auto'}
                                                height={'auto'}
                                                alt='preview'
                                                src={IMAGE_URL + medical.ID + '/' + image.filename}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleClick(index, type)}
                                            />
                                        </div>
                                        <div className="filename">
                                            {image.filename}
                                        </div>
                                        <div className="uploaded">
                                            {image.date_uploaded}
                                        </div>
                                        <div className="delete">
                                            <Button
                                                onClick={() => deleteImage(image.filename)}
                                            >
                                                <DeleteIcon
                                                    fontSize={'medium'}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

}