import { useContext } from "react"
import { Link } from "react-router-dom"
import AuthContext from "../Auth/AuthContext"

export default function Home() {

    const context = useContext(AuthContext)

    return (
        <div className="main">
            <h1>Hello {context?.auth?.displayName?.charAt(0).toUpperCase() + context?.auth?.displayName?.slice(1)}!</h1>
            <div className="menu-holder" >
                <div className="home-menu-item">
                    <Link to="/clients"> Páciensek </Link>
                </div>
                <div className="home-menu-item">
                    <Link to="/add"> Páciens hozzáadása </Link>
                </div>
            </div>
            <div className="menu-holder" >
                <div className="home-menu-item">
                    <Link to="/education/4-5"> Alsó 4 hónap Felső 5 hónap </Link>
                </div>
                <div className="home-menu-item">
                    <Link to="/education/7-8"> Alsó 7 hónap Felső 8 hónap </Link>
                </div>
                <div className="home-menu-item">
                    <Link to="/education/plan"> Tervezés </Link>
                </div>
                <div className="home-menu-item">
                    <Link to="/education/attachment"> Attachment </Link>
                </div>
                <div className="home-menu-item">
                    <Link to="/education/protocol"> Protokoll </Link>
                </div>
            </div>
        </div>
    )
}