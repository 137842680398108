import { Paper } from "@material-ui/core";
import { useState } from "react";
import Slider from "../../Components/Slider";

export default function Plan() {

    const [sliderIndex, setSliderIndex] = useState(0)
    const [showSlider, setShowSlider] = useState(false)

    const plans = [
        'https://smilezor.hu/wp-content/uploads/2020/09/F0.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F1.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F2.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F3.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F4.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F5.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F6.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F7.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F8.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F9.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/F10.png',

        'https://smilezor.hu/wp-content/uploads/2020/09/A0.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A1.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A2.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A3.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A4.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A5.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A6.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A7.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A8.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A9.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A10.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A11.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A12.png',
        'https://smilezor.hu/wp-content/uploads/2020/09/A13.png',

    ]

    const handleClick = (index) => {
        setSliderIndex(index)
        setShowSlider(true)
    }

    return (
        <>
            {showSlider &&
                <Slider
                    isAbsolute={true}
                    images={plans}
                    index={sliderIndex}
                    setShowSlider={setShowSlider} />
            }
            <Paper
                className="edu scrollbox_holder"
                square >
                <div className="scrollbox" style={{ display: 'flex', flexDirection: 'column' }}>
                    <h3 align="center"> Tervezés </h3>
                    <div className="image-grid">
                        {
                            plans.map((image, index) => {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        key={index}
                                        onClick={() => handleClick(index)}
                                        rel="noreferrer"
                                        className="image_card" >
                                        <img
                                            alt='edu-preview'
                                            src={image} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Paper>
        </>
    )
}