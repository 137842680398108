import { useEffect, useRef, useState } from "react"
import { Button, CircularProgress, MenuItem, Paper, TextField } from "@material-ui/core"
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { hu } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';

import { IMAGE_URL, A_SHIFT, A_METHODS } from "../Constants"
import useApi from "../API/API"
import { useSnackbar } from "notistack"
import { AddSnack } from "../Components/Snacks"

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SaveIcon from '@material-ui/icons/Save'

export default function Form(props) {

    const API = useApi()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const initialClientData = {
        ID: -1,
        personal_information: {
            name: '',
            email: '',
            birthday: new Date(),
            address: '',
            phone: '+36'
        },
        health_information: {
            book_no: '',
            price_a: '',
            price_b: '',
            complains: '',
            upper_shift: '',
            lower_shift: '',
            upper: '',
            lower: '',
            overjet: '',
            overbite: '',
            cross_bite: '',
            implement: [],
            remove: [],
            keep_gap: [],
        },
        pictures: [],
        notes: []
    }

    const selectRefs = {
        upper_shift: useRef(),
        lower_shift: useRef(),
        upper: useRef(),
        lower: useRef(),
        overjet: useRef(),
        overbite: useRef(),
        cross_bite: useRef(),
    }

    const [step, setStep] = useState(0)
    const [formData, setFormData] = useState(props?.location?.state?.client || initialClientData)
    const [oldPics, setOldPics] = useState([])
    const [picTeeth, setPicTeeth] = useState([])
    const [picXray, setPicXray] = useState([])
    const [uploadState, setUploadState] = useState('')

    useEffect(() => {
        formData?.health_information?.implement.forEach(i => {
            document.querySelector(`input[name="implement"][value="${i}"]`).checked = true
        })
        formData?.health_information?.remove.forEach(i => {
            document.querySelector(`input[name="remove"][value="${i}"]`).checked = true
        })
        formData?.health_information?.keep_gap.forEach(i => {
            document.querySelector(`input[name="keep_gap"][value="${i}"]`).checked = true
        })
        let oldImages = []
        Object.keys(formData?.pictures).forEach(key => {
            formData?.pictures[key].forEach(i => oldImages.push(i))
        })
        setOldPics(oldImages)
    }, [formData.health_information.implement, formData.health_information.remove, formData.health_information.keep_gap, formData.pictures])

    const onInputChange = async (e) => {
        const { name, value } = e.target
        let belongs
        let isArr

        try {
            belongs = e.target.getAttribute('belongs')
            isArr = e.target.getAttribute('isarr')
        } catch (err) {
            belongs = selectRefs[e.target.name].current.node.getAttribute('belongs')
        }

        if (isNaN(name)) {
            if (isArr === '1') {
                formData[belongs][name] = Array.from(
                    document.querySelectorAll(`input[belongs="${belongs}"][name="${name}"]`))
                    .filter(e => e.checked)
                    .map(e => e.value)
            } else if (belongs) {
                formData[belongs][name] = value
            } else {
                formData[name] = value
            }
        }

        setFormData({ ...formData })
    }

    const onInputChangeBDate = (val) => {
        formData.personal_information.birthday = val
        setFormData({ ...formData })
    }

    const onSubmit = () => {
        setUploadState('in-progress')
        API.post('smd/v1/client', formData)
            .then(res => {
                enqueueSnackbar('Páciens adatai sikeresen rögzültek.', AddSnack(closeSnackbar, 'success'))
                if (res.data !== 0) {
                    formData.ID = res.data
                    setFormData({ ...formData })
                }
                noteUpload()
                fileUpload()
            })
    }

    const noteUpload = () => {
        API.post('smd/v1/note', {
            ID: formData.ID,
            message: formData.tmpnote
        })
    }

    const fileUpload = () => {

        let files = []

        picTeeth.forEach(file => {
            let data = new FormData()
            data.append('pic_teeth[]', file)
            data.append('ID', formData.ID)
            files.push(API.post('smd/v1/files', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
                enqueueSnackbar(`${file.name} sikeresen feltöltve.`, AddSnack(closeSnackbar, 'success'))
            }))
        })

        picXray.forEach(file => {
            let data = new FormData()
            data.append('pic_xray[]', file)
            data.append('ID', formData.ID)
            files.push(API.post('smd/v1/files', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
                enqueueSnackbar(`${file.name} sikeresen feltöltve.`, AddSnack(closeSnackbar, 'success'))
            }))

        })

        Promise.allSettled(files).then(() => setUploadState('success'))
    }

    return (
        <Paper
            className="scrollbox_holder"
            square
        >
            <div className="scrollbox">
                <div className="scrollbox_inner">
                    <form className="add-form">
                        <div className={step === 0 ? "step-active" : "step"} >
                            <h3 align="center" className="subtitle"> Személyes adatok </h3>
                            <TextField
                                className="full-input"
                                inputProps={{ belongs: "personal_information" }}
                                name="name"
                                onChange={onInputChange}
                                value={formData.personal_information.name}
                                label="Név" />
                            <TextField
                                className="full-input"
                                inputProps={{ belongs: "personal_information", type: "email" }}
                                name="email"
                                onChange={onInputChange}
                                value={formData.personal_information.email}
                                label="E-mail" />
                            <MuiPickersUtilsProvider locale={hu} utils={DateFnsUtils}>
                                <DatePicker
                                    className="full-input"
                                    variant="outline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    label="Születésnap"
                                    inputProps={{ belongs: "personal_information" }}
                                    name="birthday"
                                    value={formData.personal_information.birthday}
                                    onChange={onInputChangeBDate} />
                            </MuiPickersUtilsProvider>
                            <TextField
                                className="full-input"
                                inputProps={{ belongs: "personal_information" }}
                                name="address"
                                onChange={onInputChange}
                                value={formData.personal_information.address}
                                label="Cím" />
                            <TextField
                                className="full-input"
                                inputProps={{ belongs: "personal_information" }}
                                name="phone"
                                onChange={onInputChange}
                                value={formData.personal_information.phone}
                                label="Telefonszám" />
                        </div>
                        <div className={step === 1 ? "step-active" : "step"} >
                            <h3 align="center" className="subtitle"> Egészségügyi adatok </h3>
                            <TextField
                                className="full-input"
                                inputProps={{ belongs: "health_information" }}
                                name="book_no"
                                onChange={onInputChange}
                                value={formData.health_information.book_no}
                                label="Kiskönyv száma" />
                            <TextField
                                className="full-input"
                                type="number"
                                inputProps={{ belongs: "health_information" }}
                                name="price_a"
                                onChange={onInputChange}
                                value={formData.health_information.price_a}
                                label="Kezelés ára" />
                            <TextField
                                type="number"
                                inputProps={{ belongs: "health_information" }}
                                name="price_b"
                                onChange={onInputChange}
                                value={formData.health_information.price_b}
                                className="full-input"
                                label="Fizetve" />
                            <TextField
                                className="full-input"
                                label="Panaszok"
                                multiline
                                maxRows={4}
                                inputProps={{ belongs: "health_information" }}
                                name="complains"
                                onChange={onInputChange}
                                value={formData.health_information.complains} />
                            <div className="col">
                                <TextField
                                    className="half-input"
                                    select
                                    inputRef={selectRefs.upper_shift}
                                    label="Felső középvonal"
                                    inputProps={{ belongs: "health_information" }}
                                    name="upper_shift"
                                    onChange={onInputChange}
                                    value={formData.health_information.upper_shift} >
                                    {A_SHIFT.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    className="half-input"
                                    select
                                    inputRef={selectRefs.lower_shift}
                                    label="Alsó középvonal"
                                    inputProps={{ belongs: "health_information" }}
                                    name="lower_shift"
                                    onChange={onInputChange}
                                    value={formData.health_information.lower_shift} >
                                    {A_SHIFT.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className={step === 2 ? "step-active" : "step"} >
                            <h3 align="center" className="subtitle"> Adatok </h3>
                            <TextField
                                className="full-input"
                                select
                                inputRef={selectRefs.upper}
                                label="Felső középvonal"
                                inputProps={{ belongs: "health_information" }}
                                name="upper"
                                onChange={onInputChange}
                                value={formData.health_information.upper} >
                                {A_METHODS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                className="full-input"
                                select
                                inputRef={selectRefs.lower}
                                label="Alsó középvonal"
                                inputProps={{ belongs: "health_information" }}
                                name="lower"
                                onChange={onInputChange}
                                value={formData.health_information.lower} >
                                {A_METHODS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                className="full-input"
                                select
                                inputRef={selectRefs.overjet}
                                label="Overjet"
                                inputProps={{ belongs: "health_information" }}
                                name="overjet"
                                onChange={onInputChange}
                                value={formData.health_information.overjet} >
                                {A_METHODS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                className="full-input"
                                select
                                inputRef={selectRefs.overbite}
                                label="Overbite"
                                inputProps={{ belongs: "health_information" }}
                                name="overbite"
                                onChange={onInputChange}
                                value={formData.health_information.overbite} >
                                {A_METHODS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                className="full-input"
                                select
                                inputRef={selectRefs.cross_bite}
                                label="Hátsó keresztharapás"
                                belongs={{ belongs: "health_information" }}
                                inputProps={{ belongs: "health_information" }}
                                name="cross_bite"
                                onChange={onInputChange}
                                value={formData.health_information.cross_bite} >
                                {A_METHODS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className={step === 3 ? "step-active" : "step"} >
                            <TextField
                                className="full-input"
                                label="Megjegyzés"
                                multiline
                                maxRows={4}
                                name="tmpnote"
                                onChange={onInputChange}
                                value={formData.tmpnote} />
                            <h3 align="center" className="subtitle"> Pótlások jelölése </h3>
                            <div className="outer-kriz">
                                <div class="inner-kriz q1"><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="18" class="form-check-input" /><span> 18 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="17" class="form-check-input" /><span> 17 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="16" class="form-check-input" /><span> 16 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="15" class="form-check-input" /><span> 15 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="14" class="form-check-input" /><span> 14 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="13" class="form-check-input" /><span> 13 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="12" class="form-check-input" /><span> 12 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="11" class="form-check-input" /><span> 11 </span></label></div>
                                <div class="inner-kriz q2"><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="21" class="form-check-input" /><span> 21 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="22" class="form-check-input" /><span> 22 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="23" class="form-check-input" /><span> 23 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="24" class="form-check-input" /><span> 24 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="25" class="form-check-input" /><span> 25 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="26" class="form-check-input" /><span> 26 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="27" class="form-check-input" /><span> 27 </span></label><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="28" class="form-check-input" /><span> 28 </span></label></div>
                                <div class="inner-kriz q4"><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="48" class="form-check-input" /><span> 48 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="47" class="form-check-input" /><span> 47 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="46" class="form-check-input" /><span> 46 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="45" class="form-check-input" /><span> 45 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="44" class="form-check-input" /><span> 44 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="43" class="form-check-input" /><span> 43 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="42" class="form-check-input" /><span> 42 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="41" class="form-check-input" /><span> 41 </span></label></div>
                                <div class="inner-kriz q3"><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="31" class="form-check-input" /><span> 31 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="32" class="form-check-input" /><span> 32 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="33" class="form-check-input" /><span> 33 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="34" class="form-check-input" /><span> 34 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="35" class="form-check-input" /><span> 35 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="36" class="form-check-input" /><span> 36 </span></label> <label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="37" class="form-check-input" /><span> 37 </span></label><label><input belongs="health_information" isarr="1" name="implement" onChange={onInputChange} type="checkbox" value="38" class="form-check-input" /><span> 38 </span></label></div>
                            </div>

                            <h3 align="center" className="subtitle"> Eltávolitandó fogak </h3>
                            <div className="outer-kriz">
                                <div class="inner-kriz q1"><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="18" class="form-check-input" /><span> 18 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="17" class="form-check-input" /><span> 17 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="16" class="form-check-input" /><span> 16 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="15" class="form-check-input" /><span> 15 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="14" class="form-check-input" /><span> 14 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="13" class="form-check-input" /><span> 13 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="12" class="form-check-input" /><span> 12 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="11" class="form-check-input" /><span> 11 </span></label></div>
                                <div class="inner-kriz q2"><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="21" class="form-check-input" /><span> 21 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="22" class="form-check-input" /><span> 22 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="23" class="form-check-input" /><span> 23 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="24" class="form-check-input" /><span> 24 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="25" class="form-check-input" /><span> 25 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="26" class="form-check-input" /><span> 26 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="27" class="form-check-input" /><span> 27 </span></label><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="28" class="form-check-input" /><span> 28 </span></label></div>
                                <div class="inner-kriz q4"><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="48" class="form-check-input" /><span> 48 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="47" class="form-check-input" /><span> 47 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="46" class="form-check-input" /><span> 46 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="45" class="form-check-input" /><span> 45 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="44" class="form-check-input" /><span> 44 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="43" class="form-check-input" /><span> 43 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="42" class="form-check-input" /><span> 42 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="41" class="form-check-input" /><span> 41 </span></label></div>
                                <div class="inner-kriz q3"><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="31" class="form-check-input" /><span> 31 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="32" class="form-check-input" /><span> 32 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="33" class="form-check-input" /><span> 33 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="34" class="form-check-input" /><span> 34 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="35" class="form-check-input" /><span> 35 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="36" class="form-check-input" /><span> 36 </span></label> <label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="37" class="form-check-input" /><span> 37 </span></label><label><input belongs="health_information" isarr="1" name="remove" onChange={onInputChange} type="checkbox" value="38" class="form-check-input" /><span> 38 </span></label></div>
                            </div>

                            <h3 align="center" className="subtitle"> Rések fenntartása </h3>
                            <div className="outer-kriz">
                                <div class="inner-kriz q1"><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="18" class="form-check-input" /><span> 18 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="17" class="form-check-input" /><span> 17 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="16" class="form-check-input" /><span> 16 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="15" class="form-check-input" /><span> 15 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="14" class="form-check-input" /><span> 14 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="13" class="form-check-input" /><span> 13 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="12" class="form-check-input" /><span> 12 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="11" class="form-check-input" /><span> 11 </span></label></div>
                                <div class="inner-kriz q2"><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="21" class="form-check-input" /><span> 21 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="22" class="form-check-input" /><span> 22 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="23" class="form-check-input" /><span> 23 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="24" class="form-check-input" /><span> 24 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="25" class="form-check-input" /><span> 25 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="26" class="form-check-input" /><span> 26 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="27" class="form-check-input" /><span> 27 </span></label><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="28" class="form-check-input" /><span> 28 </span></label></div>
                                <div class="inner-kriz q4"><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="48" class="form-check-input" /><span> 48 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="47" class="form-check-input" /><span> 47 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="46" class="form-check-input" /><span> 46 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="45" class="form-check-input" /><span> 45 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="44" class="form-check-input" /><span> 44 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="43" class="form-check-input" /><span> 43 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="42" class="form-check-input" /><span> 42 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="41" class="form-check-input" /><span> 41 </span></label></div>
                                <div class="inner-kriz q3"><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="31" class="form-check-input" /><span> 31 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="32" class="form-check-input" /><span> 32 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="33" class="form-check-input" /><span> 33 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="34" class="form-check-input" /><span> 34 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="35" class="form-check-input" /><span> 35 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="36" class="form-check-input" /><span> 36 </span></label> <label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="37" class="form-check-input" /><span> 37 </span></label><label><input belongs="health_information" isarr="1" name="keep_gap" onChange={onInputChange} type="checkbox" value="38" class="form-check-input" /><span> 38 </span></label></div>
                            </div>
                        </div>
                        <div className={step === 4 ? "step-active" : "step"} >
                            <h3 align="center" className="subtitle"> Képek feltöltése </h3>
                            <div className="col-center">
                                <Button
                                    style={{ width: '50%' }}
                                    component="label" >
                                    Képek feltöltése
                                    <input
                                        type="file"
                                        multiple
                                        hidden
                                        onChange={(e) => setPicTeeth([...e.target.files])}
                                    />
                                </Button>
                                <Button
                                    style={{ width: '50%' }}
                                    component="label" >
                                    Röntgenképek feltöltése
                                    <input
                                        type="file"
                                        multiple
                                        hidden
                                        onChange={(e) => setPicXray([...e.target.files])}
                                    />
                                </Button>
                            </div>
                            {oldPics.length > 0 &&
                                <h3 align="center" className="subtitle"> Feltöltött képek </h3>
                            }
                            <div className="image-grid" style={{ marginTop: '20px' }}>
                                {
                                    oldPics.map((image, index) => {
                                        return (
                                            <a key={index} href={IMAGE_URL + image} target="_blank" rel="noreferrer" className="image_card">
                                                <img src={IMAGE_URL + formData.ID + '/' + image} alt="preview" />
                                            </a>
                                        )
                                    })
                                }
                            </div>
                            {picTeeth.length > 0 &&
                                <h3 align="center" className="subtitle"> Képek </h3>
                            }
                            <div className="image-grid" style={{ marginTop: '20px' }}>
                                {
                                    picTeeth.map((image, index) => {
                                        return (
                                            <a key={index} href={URL.createObjectURL(image)} target="_blank" rel="noreferrer" className="image_card">
                                                <img src={URL.createObjectURL(image)} alt="preview" />
                                            </a>
                                        )
                                    })
                                }
                            </div>
                            {picXray.length > 0 &&
                                <h3 align="center" className="subtitle"> Röntgen képek </h3>
                            }
                            <div className="image-grid" style={{ marginTop: '20px' }}>
                                {
                                    picXray.map((image, index) => {
                                        return (
                                            <a key={index} href={URL.createObjectURL(image)} target="_blank" rel="noreferrer" className="image_card">
                                                <img src={URL.createObjectURL(image)} alt="preview" />
                                            </a>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col step-buttons" >
                {step > 0 &&

                    <Button onClick={() => setStep(step - 1)}> <ArrowBackIcon className="button-icon" /> Előző </Button>
                }
                {step < 1 &&
                    <div></div>
                }
                {step < 4 &&
                    <Button onClick={() => setStep(step + 1)}> Következő <ArrowForwardIcon className="button-icon" /></Button>
                }
                {step === 4 &&
                    <>
                        <div></div>

                        <Button
                            style={{ position: 'relative' }}
                            className={uploadState}
                            onClick={onSubmit}
                        >
                            Küldés
                            <SaveIcon className="button-icon" />
                            {uploadState === 'in-progress' &&
                                <CircularProgress size={24} />
                            }
                        </Button>
                    </>
                }
            </div>
        </Paper>
    )
}