import { Paper } from "@material-ui/core";

export default function Protocol() {
    return (
        <Paper
            className="edu scrollbox_holder"
            square >
            <div className="scrollbox protocol">
                <h3 align="center">PROTOKOL SMILEZOR FOGSZABÁLYZÓ KÉSZÜLÉKHEZ</h3>
                <h4>ALIGNER TÍPUSÚ/LÁTHATATLAN FOGSZABÁLYOZÁS</h4>
                <p>A Smilezor láthatatlan fogszabályozó rendszer áttetsző (láthatatlan, biokompatibilis műanyag) sínek sorozata, amely lépésről-lépésre kíméletesen mozgatja a fogakat az ideális helyzetükbe. Alapja egy rendkívül pontos és hatékony fogászati szoftver, amivel képesek vagyunk megtervezni az elvárt állapotot. A legújabb technikával bíró szoftvernek köszönhetően ez az esztétikus és higiénikus fogszabályozó rendszer sokkal pontosabban és gyorsabban működik, mint egy hagyományos, fogakra rögzített fogszabályzó. A tervezést követően 3D nyomtató segítségével állítjuk elő a készüléket. Főként felnőtt pácienseknek ajánlott, mert az eljárás elsősorban&nbsp;maradó fogazatban alkalmazandó.</p>
                <h4>KEZELÉSI LEHETŐSÉGEK</h4>
                <p>Az aligner típusú kezelési módszerrel kisebb fogazati eltérések kezelhetők:</p>
                <ul>
                    <li>
                        <p>frontfogak torlódása/résessége (legfeljebb 4mm)</p>
                    </li>
                    <li>
                        <p>oldalsó fogcsoport-, vagy egy fog kereszt harapása</p>
                    </li>
                    <li>
                        <p>fogívek tágításában és szűkítésében</p>
                    </li>
                    <li>
                        <p>elülső nyitott harapás zárásában</p>
                    </li>
                    <li>
                        <p>egy-két fog, vagy fogcsoport extrudálásában</p>
                    </li>
                    <li>
                        <p>rögzített készülékes kezelés befejezéséhez, vagy azzal párosítva</p>
                    </li>
                    <li>
                        <p>korábbi rögzített fogszabályzó kezelés retainer nélküli recidívája esetén</p>
                    </li>
                </ul>
                <h4>A KÉSZÜLÉK KORLÁTAI</h4>
                <ul>
                    <li>
                        <p>4 mm-nél nagyobb résesség/torlódás</p>
                    </li>
                    <li>
                        <p>Hátsó fogak rotációja</p>
                    </li>
                    <li>
                        <p>A hármasegység beállítása</p>
                    </li>
                    <li>
                        <p>A fogak tengelyállásának beállítása</p>
                    </li>
                    <li>
                        <p>A középvonal pontos beállítása</p>
                    </li>
                    <li>
                        <p>A kezeléstől nem lehet elvárni, hogy a rögzített készülékkel megegyező precizitású eredményt adjon</p>
                    </li>
                </ul>
                <h4>VISELÉSI IDŐ</h4>
                <p>Az átlátszó síneket a nap 22 órájában folyamatosan viselni kell, minden sínt két hétig. Ha valamelyik nap ez az idő nem teljesül, akkor azt a napot nem kell számolni, tehát annyival tovább tart a kezelés. A gyakorlatban a páciens számára az étkezés, illetve a tisztítás idejére engedélyezett a sín mellőzése.</p>
                <h4>A LEMEZEK ILLESZKEDÉSE</h4>
                <p>Havonta általában 0,5-1,00 - mm-el változtatható a fogak helyzete. Minden egyes sín tulajdonképpen egy-egy lépés a megtervezett állapot felé, ezért&nbsp;kezdetben nem illeszkednek tökéletesen a fogakra. 1- 2 napig billeghetnek, felemelkedhetnek és később sem szükségszerű, hogy a frontfogakra tökéletesen rásimuljanak.</p>
                <h4>KI- ÉS BEHELYEZÉS</h4>
                <p>A síneket, esetenként nehéz levenni. Ebben az esetben használja az általunk biztosított segédeszközt, amellyel a szélekbe kapaszkodva a sín megragadható.</p>
                <h4>TISZTÍTÁS/TÁROLÁS</h4>
                <p>A páciens saját maga teszi be és távolítja el a készüléket, és fogait minden egyes étkezés után meg kell mosnia és utána lehet visszahelyezni a szájba.&nbsp; Étkezéskor és fogmosáskor értelemszerűen a készülékeket el kell távolítani a szájból. Kivétel után és berakás előtt langyos vízzel érdemes leöblíteni. Fertőtlenítéséhez fogszabályozó készülék tisztító tabletta (pl. Corega Tabs) ajánlott. Az aktuálisan hordott síneket mindig a hozzá kapott tartó dobozban, hiszen az megvédi azokat a szennyeződésektől és a sérülésektől.</p>

                <h3 align="center">KIEGÉSZÍTŐ KEZELÉSEK</h3>
                <h4>ZOMÁNC POLÍROZÁS (SZTRIPPELÉS)</h4>
                <p>A zománc redukció teljesen ártalmatlan a fogakra. Kontaktpontonként kb. 0,5&nbsp;mm zománc csiszolható el.</p>
                <h4>SEGÉDELEMEK/ATTACHEMENTEK</h4>
                <p>Egyes&nbsp;fogmozgások eléréséhez, (mint amilyen pl. a rágófogak rotációja, frontfogak angulációja) 1-2 mm&nbsp;vastagságú, segédalakzatokat kell a fogakra ragasztani fogszínű kompozit anyagból, amelyek akár az egész kezelés alatt fent maradnak a fogakon.</p>
                <p>A segédelemek felragasztásának folyamata:</p>
                <ol>
                    <li>
                        <p>A fogfelszín tisztítása fluormentes pasztával</p>
                    </li>
                    <li>
                        <p>Öblítés és szárítás</p>
                    </li>
                    <li>
                        <p>Savazás 30 másodpercig</p>
                    </li>
                    <li>
                        <p>Bond felvitele a fogfelszínre</p>
                    </li>
                    <li>
                        <p>Megvilágítás</p>
                    </li>
                    <li>
                        <p>A sablon megtöltése folyékony kompozittal</p>
                    </li>
                    <li>
                        <p>A sablon helyére illesztése</p>
                    </li>
                    <li>
                        <p>Megvilágítás</p>
                    </li>
                    <li>
                        <p>A sablon eltávolítása</p>
                    </li>
                </ol>
                <p>A sablon eltávolítása után az alakzat/ok a fogakon maradnak. Az ilyen terv alapján elkészült sínek az adott fogakat ezeknek az elemeknek a segítségével hatékonyan tudják forgatni, mozgatni, húzni, mintha bracket lenne rajtuk.</p>
                <h4>RETAINER</h4>
                <p>A kezelés befejeztével elkészül egy rögzített retenciós sín a fogak belső felszínére, szintén 3D nyomtatott technológiával, szükség esetén új lenyomat alapján. Ezt a kezelő orvos adhezív technikával rögzíti a sikeres kezelés végén az alsó és felső frontfogak belső (palatinális/lingualis) felszínére</p>
            </div>
        </Paper>
    )
}