import CloseIcon from '@material-ui/icons/Close'
import { Button, Tooltip } from '@material-ui/core'

export const NetworkErrSnack = (close, secondaryButton) => {
    return {
        variant: 'error',
        persist: true,
        action: (key) => {
            return (
                <>
                    {secondaryButton &&
                        <Button
                            style={{ color: 'white' }}
                            onClick={() => { secondaryButton.func.call(key) }}>
                            {secondaryButton.text}
                            <Tooltip title={secondaryButton.message}>
                                <secondaryButton.icon />
                            </Tooltip>
                        </Button>
                    }
                    <Button
                        style={{ color: 'white' }}
                        onClick={() => { close(key) }}>
                        <CloseIcon />
                    </Button>
                </>
            )
        }
    }
}

export const AddSnack = (close, variant, secondaryButton) => {
    return {
        variant: variant,
        persist: false,
        action: (key) => {
            return (
                <>
                    {secondaryButton &&
                        <Button
                            style={{ color: 'white' }}
                            onClick={() => { secondaryButton.func.call(key) }}>
                            {secondaryButton.text}
                        </Button>
                    }
                    <Button
                        style={{ color: 'white' }}
                        onClick={() => { close(key) }}>
                        <CloseIcon />
                    </Button>
                </>
            )
        }
    }
}