import { Paper } from "@material-ui/core";

export default function Result45() {
    return (
        <Paper
            className="edu scrollbox_holder"
            square >
            <div className="scrollbox">
                <div className="edu-image-holder">
                    <h3 align="center"> Előtte </h3>
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/A-00.png" alt="edu" />
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/elotte4-3-scaled.jpg" alt="edu" />
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/elotte1-1-scaled.jpg" alt="edu" />
                </div>
                <div className="edu-image-holder">
                    <h3 align="center"> Utánna </h3>
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/F-00.png" alt="edu" />
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/elpotlas-1-nappal-kesobb6-scaled.jpg" alt="edu" />
                    <img src="https://smilezor.hu/wp-content/uploads/2020/09/elpotlas-azonnal1-scaled.jpg" alt="edu" />
                </div>
            </div>
        </Paper>
    )
}